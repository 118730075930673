@use 'styles/_variables.scss' as *;
.errorContainer {
  height: 100%;
  padding: 3 * $global-padding 0;
  background-color: $pale-gray-2;
  text-align: center;
}

.errorContent {
  max-width: 35rem;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
}

.large {
  font-size: 3rem;
}

.errorImage {
  max-width: 30rem;
}

.errorType {
  font-weight: lighter;
  color: $indigo;
  margin-bottom: 0;
}
